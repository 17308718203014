import '../globals';

/* global css imports */
import 'angular/angular-csp.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'ui-select/dist/select.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import '../css/nga.all.min.css';

import '../css/site.less';
