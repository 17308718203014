declare global {
    interface Number {
        thousandsSeperator(): String;
    }
    interface Array<T> {
        merge(entity: T, keySelectorFunc: (value: T) => any): void;
        replace(entity: T, keySelectorFunc: (value: T) => any): void;
        remove(entity: T, keySelectorFunc: (value: T) => any): void;
    }
    interface Window {
        openWindow(href:string, id: string, caption: string, onClosed: () => void); void;
    }
}
Number.prototype.thousandsSeperator = function (): string {
    return Number(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Array.prototype.merge = function <T>(entity: T, keySelectorFunc: (value: T) => any): void {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0)
        Object.assign(this[index], entity);
    else
        this.push(entity);
};

Array.prototype.replace = function <T>(entity: T, keySelectorFunc: (value: T) => any): void {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0)
        this[index] = entity;
    else
        this.push(entity);
};

Array.prototype.remove = function <T>(entity: T, keySelectorFunc: (value: T) => any): void {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0)
        this.splice(index, 1);
};

Window.prototype.openWindow = function (href:string, id: string, caption: string, onClosed: () => void) {
    console.log('window.open', href, id);
    var targetWindow = window.open(href, undefined, 'width=1200, height=600, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
    if (targetWindow) {
        targetWindow.document.write(`<html><head><title>${caption}</title></head><body><div style="align:center"><img src=\"/Content/loading.gif"/><br/>Loading...</div></body></html>`);
        targetWindow.location.href = href;
        const parentWindow = window;
        var timer = setInterval(function () {
            if (targetWindow.closed) {
                clearInterval(timer);
                console.log(parentWindow.name, 'child closed', href);
                if (onClosed)
                    onClosed();
            }
        }, 100);
        return targetWindow;
    } else {
        console.log(targetWindow, 'new window didnt open?');
        //alert('Popup error. Please tell Wes.');
    }
};

export {}
